import React from "react";
import Layout from "../components/App/Layout";
import Navbar from "../components/App/Navbar";
import Footer from "../components/App/Footer";
import ContactForm from "../components/Contact/ContactForm";
import phone from "../assets/images/phone.svg";
import email from "../assets/images/email.svg";
import whatsapp from "../assets/images/whatsapp.svg";
import location from "../assets/images/location.svg";

const Contact = () => {
  return (
    <Layout>
      <Navbar />
      <div className="page">
        <section className="contact-us-section contact-us-section--contact-page">
          <div>
            <div className="container">
              <div className="section-title">
                <h2>We're here to support you</h2>
                <p>
                  We help small and medium business owners in Singapore obtain zero-collateral, fast and flexible financing to grow their business. Interested? Let's chat.
                </p>
              </div>
            </div>  
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="contact-info-wrapper">
                  <label className="contact-info" htmlFor="phone">
                    <img src={phone} alt="" />
                    +65 985 047781
                  </label>
                  <label className="contact-info" htmlFor="email">
                    <img src={email} alt="" />
                    info@seaeasycapital.com
                  </label>
                  <label className="contact-info" htmlFor="whatsapp">
                    <img src={whatsapp} alt="" />
                    +65 985 047781
                  </label>
                  <label className="contact-info" htmlFor="address">
                    <img src={location} alt="" />
                    38, North Canal Road, Singapore 059294
                  </label>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <ContactForm />
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </Layout>
  );
};

export default Contact;
